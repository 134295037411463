import React, { useEffect, useState } from "react";
import logo from "../../assets/EthioStarLogo2.jpg";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthProvider";
import { useAcc } from "../../context/AccountProvider";
import { useApp } from "../../context/AppProvider";
import AssessmentIcon from "@mui/icons-material/Assessment";
// import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
// import PendingActionsIcon from "@mui/icons-material/PendingActions";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Dropdown } from "antd";

const Sidebar = () => {
  //! Hooks
  const auth = useAuth();
  const { getMyNotifications } = useAcc();
  const { selectedClientPage, setSelectedClientPage, formatDate } = useApp();

  //! States
  const [topNotifications, setTopNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationCounts, setNotificationCounts] = useState({
    read: 0,
    unread: 0,
  });

  //! Effects
  useEffect(() => {
    const fetchMyNotifications = async () => {
      setLoading(true);
      setError(null);

      try {
        await getMyNotifications((data) => {
          const top3Notifications = data.slice(0, 3);

          const readCount = top3Notifications.filter(
            (notification) => notification.isRead
          ).length;
          const unreadCount = top3Notifications.filter(
            (notification) => !notification.isRead
          ).length;
          setNotificationCounts({ read: readCount, unread: unreadCount });

          const notificationList = top3Notifications.map(
            (notification, index) => ({
              key: String(index + 1),
              label: (
                <Link to="/" className="flex px-4 py-3 hover:bg-gray-100">
                  <div className="flex-shrink-0">
                    <img
                      className="rounded-full w-11 h-11"
                      src={logo}
                      alt={`Notification ${index + 1}`}
                    />
                  </div>

                  <div className="w-full ps-3">
                    <div className="text-gray-900 font-semibold text-sm mb-1.5">
                      {notification.message}
                    </div>
                    <div className="text-xs text-green-700">
                      {formatDate(notification.createdAt)}
                    </div>
                  </div>
                </Link>
              ),
            })
          );

          setTopNotifications(notificationList);
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMyNotifications();
  }, [getMyNotifications, formatDate]);

  //! Functions
  const handleNavigation = (page) => {
    setSelectedClientPage(page);
  };

  //! Constants
  const menuItems = [
    {
      key: "1",
      label: (
        <button
          className="block px-4 py-2 w-full"
          onClick={() => handleNavigation("Profile")}
        >
          <div className="flex items-center justify-start gap-2">
            <AccountBoxIcon />
            <p>Profile</p>
          </div>
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          className="block px-4 py-2 w-full"
          onClick={() => auth.logOut()}
        >
          <div className="flex items-center justify-start gap-2">
            <ExitToAppIcon />
            <p>Sign out</p>
          </div>
        </button>
      ),
    },
  ];

  const loadingItems = [
    {
      key: "1",
      label: <div>Loading notifications...</div>,
    },
  ];

  const errorItems = [
    {
      key: "1",
      label: <div>Failed to load notifications!</div>,
    },
  ];

  return (
    <div className="z-10 w-20 md:w-64 h-screen bg-white shadow-xl flex flex-col">
      <div className="flex-grow">
        {/* Logo */}
        <div className="px-6 pt-8">
          <Link to="/">
            <div className="flex items-center space-x-1">
              <img src={logo} alt="EthioStar logo" className="h-8" />
              <span className="self-center text-lg font-medium whitespace-nowrap text-green-700 hidden md:block">
                EthioStar
              </span>
            </div>
          </Link>
        </div>

        {/* Divider */}
        <div className="md:px-6 pt-4">
          <hr className="border-gray-100" />
        </div>

        {/* Major Links */}
        <div className="px-5 md:px-6 pt-4">
          <ul className="flex flex-col space-y-2">
            {/* Dashboard */}
            <li
              className={`${
                selectedClientPage === "Dashboard"
                  ? "text-green-700 font-medium"
                  : "text-gray-800"
              } hover:text-green-700`}
            >
              <button
                className="inline-block w-full pl-2 pr-4 py-2 text-sm hover:bg-smoothBg rounded"
                onClick={() => handleNavigation("Dashboard")}
              >
                <div className="flex justify-start items-center gap-2">
                  <AssessmentIcon />
                  <p className="text-sm hidden md:block">Dashboard</p>
                </div>
              </button>
            </li>

            {/* Actions */}
            {/* <li
              className={`${
                selectedClientPage === "Actions"
                  ? "text-green-700 font-medium"
                  : "text-gray-800"
              } hover:text-green-700`}
            >
              <button
                className="inline-block w-full pl-2 pr-4 py-2 text-sm hover:bg-smoothBg rounded"
                onClick={() => handleNavigation("Actions")}
              >
                <div className="flex justify-start items-center gap-2">
                  <PendingActionsIcon />
                  <p className="text-sm hidden md:block">Actions</p>
                </div>
              </button>
            </li> */}

            {/* Users */}
            {/* <li
              className={`${
                selectedClientPage === "Users"
                  ? "text-green-700 font-medium"
                  : "text-gray-800"
              } hover:text-green-700`}
            >
              <button
                className="inline-block w-full pl-2 pr-4 py-2 text-sm hover:bg-smoothBg rounded"
                onClick={() => handleNavigation("Users")}
              >
                <div className="flex justify-start items-center gap-2">
                  <SupervisedUserCircleIcon />
                  <p className="text-sm hidden md:block">Users</p>
                </div>
              </button>
            </li> */}
          </ul>
        </div>
      </div>

      {/* Divider */}
      <div className="md:px-6 pt-8">
        <hr className="border-gray-100" />
      </div>

      {/* Minor Links */}
      <div className="px-5 md:px-6 py-4">
        <ul className="flex flex-col space-y-2">
          {/* Notifications */}
          <li className="text-gray-800 hover:text-green-700">
            <Dropdown
              menu={{
                items: loading
                  ? loadingItems
                  : error
                  ? errorItems
                  : topNotifications,
              }}
              placement="topLeft"
              // trigger={["click"]}
            >
              <button
                className="inline-block w-full pl-2 pr-4 py-2 text-sm hover:bg-smoothBg rounded"
                onClick={() => handleNavigation("Notifications")}
              >
                <div className="flex justify-start items-center gap-2 relative">
                  <div
                    className={`${
                      notificationCounts.unread > 0 ? "block" : "hidden"
                    } absolute top-[3px] left-[13px] w-2 h-2 bg-red-600 rounded-full`}
                  ></div>
                  <NotificationsNoneIcon />
                  <p className="text-sm hidden md:block">Notifications</p>
                </div>
              </button>
            </Dropdown>
          </li>
        </ul>
      </div>

      {/* Logged in user info */}
      <div className="pl-6 pr-4 py-4 bg-gray-100 flex items-center justify-between gap-2">
        <div className="hidden md:flex items-center">
          <div className="flex flex-col pl-3">
            <p className="text-sm text-gray-700">
              {auth.user?.firstName} {auth.user?.lastName}
            </p>
            <p className="text-xs text-gray-700 font-light tracking-tight">
              {auth.user?.email}
            </p>
            <p className="text-green-700 text-xs font-semibold m-0">
              {auth.user?.role}
            </p>
          </div>
        </div>

        <Dropdown
          menu={{
            items: menuItems,
          }}
          placement="topLeft"
          trigger={["click"]}
        >
          <button className="flex items-center justify-center p-0 rounded bg-gray-200">
            <KeyboardArrowRightIcon />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default Sidebar;
