import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";

const PrivateRoute = ({ requiredRole }) => {
  const { user, token } = useAuth();

  if (!user || !token) return <Navigate to="/login" />;

  if (requiredRole && user.role !== requiredRole) {
    return <Navigate to="/access-denied" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
