import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/EthioStarLogo2.jpg";

const NavBar = () => {
  const navigate = useNavigate();

  return (
    <nav className="bg-white">
      <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
        {/* Logo */}
        <div className="flex items-center space-x-1 rtl:space-x-reverse">
          <img src={logo} alt="EthioStar Logo" className="h-8" />
          <span className="self-center text-xl font-semibold whitespace-nowrap text-green-700">
            EthioStar
          </span>
        </div>

        {/* Buttons */}
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            type="button"
            className="text-white bg-black hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-10 py-2 text-center "
            onClick={() => navigate("/login")}
          >
            Sign In
          </button>

          <button
            data-collapse-toggle="navbar-cta"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
            aria-controls="navbar-cta"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>

        {/* Links */}
        <div
          className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-cta"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link
                to="#"
                className="block py-2 px-3 md:p-0 text-gray-900 font-normal text-sm uppercase rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-600"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="block py-2 px-3 md:p-0 text-gray-900 font-normal text-sm uppercase rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-600"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="block py-2 px-3 md:p-0 text-gray-900 font-normal text-sm uppercase rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-600"
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="#"
                className="block py-2 px-3 md:p-0 text-gray-900 font-normal text-sm uppercase rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-green-600"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
