import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/EthioStarLogo2.jpg";

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* Nav bar */}
      <nav className="bg-white">
        <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
          {/* Logo */}
          <div className="flex items-center space-x-1 rtl:space-x-reverse">
            <img src={logo} alt="EthioStar Logo" className="h-8" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-green-700">
              EthioStar
            </span>
          </div>

          {/* Buttons */}
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button
              type="button"
              className="text-white bg-black hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-10 py-2 text-center "
              onClick={() => navigate("/login")}
            >
              Sign In
            </button>

            <button
              data-collapse-toggle="navbar-cta"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-cta"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>

      {/* Content */}
      <section className="min-h-[90vh]">
        <div className="max-w-screen-2xl h-[90vh] flex flex-row items-center justify-center mx-auto p-4">
          <div className="shadow-xl rounded border bg-white">
            <div className="flex flex-col justify-center items-center min-w-52 max-w-md px-7 py-12 my-16 mx-14">
              <p className="font-bold text-8xl text-gray-400 mb-2">403</p>
              <p className="font-semibold text-xl mb-7">Access Denied</p>
              <p className="text-sm text-center mb-10">
                You don't have the required permissions to view this page.
                Please contact your adminsitrator.
              </p>
              <button
                type="button"
                className="text-white hover:bg-green-700 bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-10 py-2 text-center "
                onClick={() => navigate("/")}
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccessDenied;
