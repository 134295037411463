import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./router/route";

// Contexts
import AppProvider from "./context/AppProvider";
import AuthProvider from "./context/AuthProvider";
import AccountProvider from "./context/AccountProvider";

// Pages
import LandingPage from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/client pages/RegisterClientPage";
import AdminDashboard from "./pages/admin pages/AdminDashboard";
import ClientDashboard from "./pages/client pages/ClientDashboard";
import StoreDashboard from "./pages/store pages/StoreDashboard";
import OperatorDashboard from "./pages/operator pages/OperatorDashboard";
import FinanceDashboard from "./pages/finance pages/FinanceDashboard";
import AccessDenied from "./pages/AccessDenied";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <AppProvider>
          <AuthProvider>
            <AccountProvider>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />

                {/* Admin pages */}
                <Route element={<PrivateRoute requiredRole="Admin" />}>
                  <Route path="/adminDashboard" element={<AdminDashboard />} />
                </Route>

                {/* Client pages */}
                <Route element={<PrivateRoute requiredRole="Client" />}>
                  <Route
                    path="/clientDashboard"
                    element={<ClientDashboard />}
                  />
                </Route>

                {/* Store keeper pages */}
                <Route element={<PrivateRoute requiredRole="Store" />}>
                  <Route path="/storeDashboard" element={<StoreDashboard />} />
                </Route>

                {/* Operator pages */}
                <Route element={<PrivateRoute requiredRole="Operator" />}>
                  <Route
                    path="/operatorDashboard"
                    element={<OperatorDashboard />}
                  />
                </Route>

                {/* Finance pages */}
                <Route element={<PrivateRoute requiredRole="Finance" />}>
                  <Route
                    path="/financeDashboard"
                    element={<FinanceDashboard />}
                  />
                </Route>

                <Route path="/access-denied" element={<AccessDenied />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </AccountProvider>
          </AuthProvider>
        </AppProvider>
      </Router>
    </div>
  );
}

export default App;
