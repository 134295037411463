import { message, notification } from "antd";
import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";

const AccountContext = createContext();
const API_URL = process.env.REACT_APP_API_KEY;

const AccountProvider = ({ children }) => {
  //! States
  const navigate = useNavigate();

  //! Effects

  //! Functions
  // For registering a new client
  const registerAction = async (data, selectedImages, setErrorMessage) => {
    try {
      const formData = new FormData();

      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("email", data.email);
      formData.append("password", data.password);
      formData.append("phoneNumber", data.phoneNumber);
      formData.append("role", "Client");

      formData.append(
        "organizationDetails",
        JSON.stringify({
          organizationName: data.organizationName,
          yearsOfOperation: parseInt(data.yearsOfOperation, 10) || 0,
          productsAndServices: data.productsAndServices,
          priorExperience: data.priorExperience,
          websiteUrl: data.websiteUrl,
          officeLocation: data.officeLocation,
        })
      );

      if (selectedImages.length > 0) {
        for (const image of selectedImages) {
          formData.append("businessLicenses", image);
        }
      }

      const response = await fetch(`${API_URL}/account/registerClient`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const res = await response.json();
        console.log(res);
        message.success("User registered successfully!");
        navigate("/login");
      } else {
        const errorRes = await response.json();
        console.error("Registration error:", errorRes);
        message.error(
          errorRes.error || "Something went wrong. Please try again."
        );
        setErrorMessage(
          errorRes.error || "Something went wrong. Please try again."
        );
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  // For registering other users (Store keeper, Operator, and Finance)
  const registerSystemUser = async (data, setActiveTab, setErrorMessage) => {
    try {
      const token = localStorage.getItem("site");

      if (!token) {
        notification.error({
          message: "Token not found!",
          description: "No authorization token found. Please log in.",
        });
        navigate("/login");
        return;
      }

      // Send request to register the system user
      const response = await fetch(`${API_URL}/account/registerUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
          role: data.role,
        }),
      });

      // Handle response
      if (response.ok) {
        const res = await response.json();
        console.log("User registered successfully:", res);
        setActiveTab("allUsers");
      } else {
        const errorRes = await response.json();
        console.error("Registration error:", errorRes);
        setErrorMessage(
          errorRes.error || "Failed to register user. Please try again."
        );
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  // For fetching client accounts that have a pending status
  const getPendingClients = async (setData) => {
    const token = localStorage.getItem("site");
    if (!token) {
      notification.error({
        message: "Token not found!",
        description: "No authorization token found. Please log in.",
      });
      navigate("/login");
      return;
    }

    const response = await fetch(`${API_URL}/account/pendingUsers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    setData(data.pendingUsers);
  };

  // For fetching details of a user by ID
  const getClientDetails = async (setUser, userId) => {
    const token = localStorage.getItem("token");
    const response = await fetch(`${API_URL}/account/clientDetails/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    setUser(data.user);
  };

  // For updating the clients account status
  const updateUserStatus = async (userId, newStatus) => {
    const token = localStorage.getItem("site");
    if (!token) {
      notification.error({
        message: "Token not found!",
        description: "No authorization token found. Please log in.",
      });
      navigate("/login");
      return;
    }

    const response = await fetch(`${API_URL}/account/updateUserStatus`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        status: newStatus,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    // navigate("/clientApproval");
    return data;
  };

  // For getting all users
  const getAllUsers = async (setAllUsers) => {
    const token = localStorage.getItem("site");
    if (!token) {
      notification.error({
        message: "Token not found!",
        description: "No authorization token found. Please log in.",
      });
      navigate("/login");
      return;
    }

    const response = await fetch(`${API_URL}/account/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    setAllUsers(data.users);
  };

  // For getting own profile info
  const getMyProfile = async (setUser) => {
    const token = localStorage.getItem("site");

    const response = await fetch(`${API_URL}/account/myProfile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    setUser(data.profile);
  };

  // For getting all user notifications
  const getMyNotifications = async (setNotifications) => {
    const token = localStorage.getItem("site");

    try {
      const response = await fetch(`${API_URL}/notification`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setNotifications(data.notifications);
    } catch (error) {
      console.error("Failed to fetch notifications:", error.message);
    }
  };

  // Function to mark a notification as read
  const markNotificationAsRead = async (notificationId) => {
    const token = localStorage.getItem("site");

    if (!token) {
      notification.error({
        message: "Token not found!",
        description: "No authorization token found. Please log in.",
      });
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/notification/${notificationId}/markAsRead`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          `Failed to mark notification as read: ${response.statusText}`
        );
      }

      const data = await response.json();
      console.log(data.message);
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return (
    <AccountContext.Provider
      value={{
        registerAction,
        registerSystemUser,
        getPendingClients,
        getClientDetails,
        updateUserStatus,
        getAllUsers,
        getMyProfile,
        getMyNotifications,
        markNotificationAsRead,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountProvider;

export const useAcc = () => {
  return useContext(AccountContext);
};
