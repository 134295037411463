import { createContext, useContext, useState } from "react";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  //! States
  const [selectedAdminPage, setSelectedAdminPage] = useState("Dashboard"); //Dashboard, Actions, Users, Notifications, Profile
  const [selectedClientPage, setSelectedClientPage] = useState("Dashboard"); //Dashboard, Notifications, Profile

  //! Effects

  //! Functions
  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  }

  return (
    <AppContext.Provider
      value={{
        formatDate,
        selectedAdminPage,
        setSelectedAdminPage,
        selectedClientPage,
        setSelectedClientPage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useApp = () => {
  return useContext(AppContext);
};
