import React, { useState } from "react";
import { Link } from "react-router-dom";
import coffeeBag from "../../assets/coffeeBag.png";
import logo from "../../assets/EthioStarLogo.jpg";
import { useAcc } from "../../context/AccountProvider";
import { Alert, Button, Form, Image, Input, InputNumber, Upload } from "antd";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TextArea from "antd/es/input/TextArea";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from "@mui/icons-material/Link";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const RegisterClientPage = () => {
  const { registerAction } = useAcc();

  //! States
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  //! Effects

  //! Functions
  const onFinish = (values) => {
    registerAction(values, selectedImages, setErrorMessage);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleChange = (info) => {
    const newImages = info.fileList.map((file) => file.originFileObj);
    setSelectedImages(newImages);
  };

  return (
    <section className="flex flex-row items-center justify-between mx-auto">
      {/* Side */}
      <div className="bg-smoothBg w-full h-screen flex flex-col">
        <p>x</p>
        <div className="flex flex-col items-center justify-center h-full gap-3">
          <p className="text-3xl font-semibold">Register!</p>
          <p className="italic font-light">
            Precision in every bean, from filter to package
          </p>
          <img src={coffeeBag} alt="Coffee bag" />
        </div>
      </div>

      {/* form */}
      <div className="bg-white w-full h-screen overflow-y-scroll">
        <div className="flex flex-col items-center justify-center my-20">
          <img src={logo} alt="EthioStar Logo" className="h-28 mb-12" />
          <p className="font-medium text-gray-400 mb-3">Welcome</p>
          <p className="font-bold text-[30px] mb-10">Sign up now</p>

          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              className="max-w-[600px] w-full mb-10 text-red-700 text-center"
            />
          )}

          <Form
            name="Client_Register_Form"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="max-w-[600px] w-full"
          >
            <p className="font-semibold text-lg mb-5">
              User Related Infromation
            </p>

            <div className="flex justify-between gap-8">
              {/* First Name */}
              <Form.Item
                name="firstName"
                className="mb-8 flex-grow"
                hasFeedback
                validateDebounce={1000}
                rules={[
                  {
                    type: "string",
                    message: "The input is not a valid name!",
                  },
                  {
                    required: true,
                    message: "First name is required!",
                  },
                ]}
              >
                <Input
                  prefix={<PersonIcon className="text-[#78C996] mx-3" />}
                  placeholder="First name"
                  className="h-9"
                  maxLength={15}
                />
              </Form.Item>

              {/* Last Name */}
              <Form.Item
                name="lastName"
                className="mb-8 flex-grow"
                hasFeedback
                validateDebounce={1000}
                rules={[
                  {
                    type: "string",
                    message: "The input is not a valid name!",
                  },
                  {
                    required: true,
                    message: "Last name is required!",
                  },
                ]}
              >
                <Input
                  prefix={<PersonIcon className="text-[#78C996] mx-3" />}
                  placeholder="Last name"
                  className="h-9"
                />
              </Form.Item>
            </div>

            {/* Email */}
            <Form.Item
              name="email"
              className="mb-8"
              hasFeedback
              validateDebounce={1000}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "E-mail address is required!",
                },
              ]}
            >
              <Input
                prefix={<EmailIcon className="text-[#78C996] mx-3" />}
                placeholder="Email Address"
                className="h-9"
              />
            </Form.Item>

            {/* Password */}
            <Form.Item
              name="password"
              className="mb-8"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Password field is required!",
                },
              ]}
            >
              {/* <Input.Password /> */}
              <Input.Password
                prefix={<LockIcon className="text-[#78C996] mx-3" />}
                placeholder="Password"
                className="h-9"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
              />
            </Form.Item>

            {/* Phone Number*/}
            <Form.Item
              name="phoneNumber"
              // className="mb-8"
              hasFeedback
              validateDebounce={1000}
              rules={[
                {
                  type: "string",
                  message: "The input is not a valid phone number!",
                },
                {
                  required: true,
                  message: "Phone number is required!",
                },
              ]}
            >
              <Input
                prefix={<LocalPhoneIcon className="text-[#78C996] mx-3" />}
                placeholder="Phone number"
                className="h-9"
              />
            </Form.Item>

            <p className="font-semibold text-lg mb-5">
              Organiztion Related Infromation
            </p>

            {/* Orgainzations Name */}
            <Form.Item
              name="organizationName"
              className="mb-8 flex-grow"
              hasFeedback
              validateDebounce={1000}
              rules={[
                {
                  type: "string",
                  message: "The input is not a valid name!",
                },
                {
                  required: true,
                  message: "Organization name is required!",
                },
              ]}
            >
              <Input
                prefix={<CorporateFareIcon className="text-[#78C996] mx-3" />}
                placeholder="Organization name"
                className="h-9"
                maxLength={15}
              />
            </Form.Item>

            <div className="flex justify-between gap-8">
              {/* Office location */}
              <Form.Item
                name="officeLocation"
                className="mb-8 flex-grow"
                hasFeedback
                validateDebounce={1000}
                rules={[
                  {
                    type: "string",
                    message: "The input is not a valid location!",
                  },
                  {
                    required: true,
                    message: "Office location is required!",
                  },
                ]}
              >
                <Input
                  prefix={<LocationOnIcon className="text-[#78C996] mx-3" />}
                  placeholder="Office location"
                  className="h-9"
                  maxLength={15}
                />
              </Form.Item>

              {/* Years of organization */}
              <Form.Item
                name="yearsOfOperation"
                className="mb-8 flex-grow"
                hasFeedback
                validateDebounce={1000}
                rules={[
                  {
                    required: true,
                    message: "Years of operation is required!",
                  },
                ]}
              >
                <InputNumber
                  prefix={<DateRangeIcon className="text-[#78C996] mx-3" />}
                  placeholder="Years of operation"
                  className="h-9 w-full"
                />
              </Form.Item>
            </div>

            {/* Website URL */}
            <Form.Item
              name="websiteUrl"
              className="mb-8 flex-grow"
              validateDebounce={1000}
              rules={[]}
            >
              <Input
                prefix={<LinkIcon className="text-[#78C996] mx-3" />}
                placeholder="Website URL"
                className="h-9"
                maxLength={75}
              />
            </Form.Item>

            {/* Products and Services */}
            <Form.Item
              name="productsAndServices"
              className="mb-8 flex-grow"
              rules={[
                {
                  type: "string",
                  message: "The input is not a valid name!",
                },
                {
                  required: true,
                  message: "Products and services is required!",
                },
              ]}
            >
              <TextArea
                placeholder="Products and services"
                className="h-9"
                autoSize
              />
            </Form.Item>

            {/* Prior Experience */}
            <Form.Item
              name="priorExperience"
              className="mb-8 flex-grow"
              rules={[
                {
                  type: "string",
                  message: "The input is not a valid name!",
                },
                {
                  required: true,
                  message: "Prior experience is required!",
                },
              ]}
            >
              <TextArea
                placeholder="Prior experience of the organization"
                className="h-9"
                autoSize
              />
            </Form.Item>

            {/* Upload File */}
            <Form.Item
              name="businessLicenses"
              label="Business License"
              rules={[
                {
                  required: true,
                  message: "Please enter at least one image for your product!",
                },
              ]}
            >
              <Upload
                multiple
                listType="picture-card"
                maxCount={5}
                onPreview={handlePreview} // Integrate preview functionality
                onChange={handleChange} // Existing function for file changes
                // action=""
              >
                <button
                  style={{
                    border: 0,
                    background: "none",
                  }}
                  type="button"
                >
                  <AddIcon />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Add image
                  </div>
                </button>
              </Upload>
            </Form.Item>

            {/* Button */}
            <Form.Item label={null}>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-green-700 hover:bg-yellow-500 h-9 font-semibold text-[16px] mb-12"
              >
                Submit
              </Button>
            </Form.Item>

            <div className="w-full flex justify-center">
              <p>
                Already have an account?
                <Link
                  to={"/login"}
                  className="text-green-700 hover:text-yellow-500"
                >
                  {" "}
                  Login using account
                </Link>
              </p>
            </div>
          </Form>

          {previewImage && (
            <Image
              wrapperStyle={{
                display: "none",
              }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(""),
              }}
              src={previewImage}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default RegisterClientPage;
