import { Breadcrumb } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ProfileDetails from "./admin related/subcomponents/ProfileDetails";

const ProfileContent = () => {
  //! States
  const [activeTab, setActiveTab] = useState("viewProfile"); // viewProfile, editProfile

  //! Functions

  const getTitleAndBreadcrumb = () => {
    switch (activeTab) {
      case "viewProfile":
        return {
          title: "My Profile",
          breadcrumb: [
            { title: <Link to="/">Home</Link> },
            { title: "Profile" },
          ],
        };
      case "editProfile":
        return {
          title: "Edit Profile",
          breadcrumb: [
            { title: <Link to="/">Home</Link> },
            {
              title: (
                <button
                  onClick={() => {
                    setActiveTab("viewProfile");
                  }}
                >
                  Profile
                </button>
              ),
            },
            { title: "Edit Profile" },
          ],
        };
      default:
        return {
          title: "My Profile",
          breadcrumb: [
            { title: <Link to="/">Home</Link> },
            { title: "Profile" },
          ],
        };
    }
  };

  const { title, breadcrumb } = getTitleAndBreadcrumb();

  const renderContent = () => {
    switch (activeTab) {
      case "viewProfile":
        return <ProfileDetails />;
      case "editProfile":
        return <ProfileDetails />;
      default:
        return <ProfileDetails />;
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-start mb-5">
        <p className="font-semibold text-lg">{title}</p>
        <p className="font-light text-[13px]">
          {activeTab === "viewProfile"
            ? "View all information related to your account"
            : "Edit your account information"}
        </p>
      </div>

      <Breadcrumb className="mb-8" items={breadcrumb} />

      <div className="relative bg-white p-5 mb-10 rounded shadow-lg flex-grow">
        {renderContent()}
      </div>
    </>
  );
};

export default ProfileContent;
