import { notification, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAcc } from "../../../context/AccountProvider";

const PendingClientsTable = ({ handleSetActiveTab }) => {
  const { getPendingClients } = useAcc();

  const handleMoreClick = (userId) => {
    handleSetActiveTab("userDetails", userId);
  };

  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Organization Name",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button
            onClick={() => {
              handleMoreClick(record.userId);
            }}
          >
            <MoreHorizIcon />
          </button>
        </Space>
      ),
    },
  ];

  //! States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pendingClients, setPendingClients] = useState([]);

  //! Effects
  useEffect(() => {
    const fetchPendingClients = async () => {
      setLoading(true);
      setError(null);
      try {
        await getPendingClients(setPendingClients);
      } catch (err) {
        setError(err.message);
        notification.error({
          message: "Error Fetching Pending Users",
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchPendingClients();
  }, [getPendingClients]);

  //! Functions
  console.log(error);

  return (
    <Table
      columns={columns}
      dataSource={pendingClients.map((user) => ({ ...user, key: user.userId }))}
      loading={loading}
    />
  );
};

export default PendingClientsTable;
