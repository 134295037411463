import React, { useEffect, useState } from "react";
import { useAcc } from "../context/AccountProvider";
import {
  Alert,
  Breadcrumb,
  Empty,
  List,
  message,
  notification,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DraftsIcon from "@mui/icons-material/Drafts";
import DeleteIcon from "@mui/icons-material/Delete";
import { useApp } from "../context/AppProvider";

// const data = [
//   {
//     title: "Ant Design Title 1",
//     message: "Ant Design Title 1",
//     isRead: false,
//   },
//   {
//     title: "Ant Design Title 2",
//     message: "Ant Design Title 2",
//     isRead: true,
//   },
//   {
//     title: "Ant Design Title 3",
//     message: "Ant Design Title 3",
//     isRead: true,
//   },
//   {
//     title: "Ant Design Title 4",
//     message: "Ant Design Title 4",
//     isRead: true,
//   },
// ];

const Notifications = () => {
  //! Hooks
  const { getMyNotifications, markNotificationAsRead } = useAcc();
  const { formatDate } = useApp();

  //! States
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //! Effects
  useEffect(() => {
    const fetchMyNotifications = async () => {
      setLoading(true);
      setError(null);

      try {
        await getMyNotifications(setNotifications);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMyNotifications();
  }, [getMyNotifications]);

  //! Functions
  const handleNotificationClick = async (notificationId) => {
    try {
      setLoading(true);
      await markNotificationAsRead(notificationId);

      // Optionally update notifications state to reflect that it's read
      setNotifications((prevNotifications) => {
        const updatedNotifications = prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, isRead: true }
            : notification
        );
        return updatedNotifications;
      });

      message.success("Notification mark as read!");
    } catch (err) {
      setError(err.message);
      notification.error({
        message: "Failed to Mark Notification",
        description: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center items-start mb-5">
        <p className="font-semibold text-lg">Notifications</p>
        <p className="font-light text-[13px]">
          A list of notifications you have revieved so far.
        </p>
      </div>

      <Breadcrumb
        className="mb-8"
        items={[
          { title: <Link to="/">Home</Link> },
          { title: "Notifications" },
        ]}
      />

      <div className="relative bg-white p-5 mb-10 rounded shadow-lg flex-grow">
        {/* Loading State */}
        {loading && (
          <div className="flex justify-center items-center h-32">
            <Spin size="large" tip="Loading notifications..." />
          </div>
        )}

        {/* Error State */}
        {error && (
          <Alert message="Error" description={error} type="error" showIcon />
        )}

        {/* No Notifications */}
        {!loading && !error && notifications.length === 0 && (
          <Empty description="No notifications available" className="py-10" />
        )}

        {/* Notifications List */}
        {!loading && !error && notifications.length > 0 && (
          <List
            header={<p className="font-bold text-[15px]">All Notifications</p>}
            pagination={{
              position: "bottom",
              align: "end",
            }}
            dataSource={notifications}
            renderItem={(notification) => (
              <List.Item
                key={notification.id}
                actions={[
                  <button
                    onClick={() => handleNotificationClick(notification.id)}
                  >
                    {notification.isRead ? (
                      <MarkunreadIcon fontSize="small" />
                    ) : (
                      <DraftsIcon
                        fontSize="small"
                        className="hover:text-green-700"
                      />
                    )}
                  </button>,
                  <button className="hover:text-red-500">
                    <DeleteIcon fontSize="small" />
                  </button>,
                ]}
                className={`${
                  notification.isRead ? "bg-gray-50" : "bg-white"
                } m-1 hover:bg-gray-100 rounded-sm`}
              >
                <List.Item.Meta
                  avatar={
                    <div className="flex justify-center items-center w-[1vw] h-[4.5vh]">
                      <div
                        className={`${
                          notification.isRead ? "hidden" : "block"
                        } w-2 h-2 rounded-full bg-green-700`}
                      ></div>
                    </div>
                  }
                  title={
                    <p
                      className={`${
                        notification.isRead
                          ? "font-medium text-gray-600"
                          : "font-semibold"
                      }`}
                    >
                      {notification.title}
                    </p>
                  }
                  description={
                    <>
                      <p
                        className={`${
                          notification.isRead ? "font-normal" : "font-medium"
                        }`}
                      >
                        {notification.message}
                      </p>
                      <p
                        className={`${
                          notification.isRead ? "font-light" : "font-normal"
                        } text-[11px] mt-2`}
                      >
                        {formatDate(notification.createdAt)}
                      </p>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </div>
    </>
  );
};

export default Notifications;
