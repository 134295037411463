import React, { useEffect, useState } from "react";
import { useAcc } from "../../../context/AccountProvider";
import { Alert, Image, List } from "antd";
import { Link } from "react-router-dom";
import BadgeIcon from "@mui/icons-material/Badge";

const Profile = () => {
  //! States
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { getMyProfile } = useAcc();

  //! Effects
  useEffect(() => {
    const fetchMyProfile = async () => {
      setLoading(true);
      setError(null);

      try {
        await getMyProfile(setUser);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMyProfile();
  }, [getMyProfile]);

  //! Functions
  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  }

  return (
    <>
      {loading && !error && <p>Loading...</p>}
      {!loading && error && (
        <Alert message="Error" description={error} type="error" showIcon />
      )}
      {!loading && !error && !user && (
        <Alert message="Error" description={error} type="error" showIcon />
      )}

      {user && (
        <div>
          {/* Full name + role*/}
          <div className="flex justify-between items-center">
            <p className="font-semibold text-lg">{`${user?.firstName} ${user?.lastName}`}</p>
            <p
              className={`px-4 py-1 rounded border text-sm ${
                user?.role === "Client"
                  ? "bg-yellow-100 text-yellow-500 border-yellow-400"
                  : user?.role === "Admin"
                  ? "bg-purple-100 text-purple-700 border-purple-400"
                  : user?.role === "Store"
                  ? "bg-blue-100 text-blue-700 border-blue-400"
                  : user?.role === "Operator"
                  ? "bg-orange-100 text-orange-700 border-orange-400"
                  : "bg-green-100 text-green-700 border-green-400"
              }`}
            >
              {user?.role}
            </p>
          </div>

          {/* userId */}
          <div className="flex justify-start items-center gap-1 text-gray-500 mb-5">
            <BadgeIcon style={{ fontSize: "13px" }} />
            <p className="font-normal italic text-[10px]">{user?.userId}</p>
          </div>

          <hr />

          {/* User Info */}
          <p className="font-medium text-[15px] mt-5 mb-3">User Information</p>

          <div className="flex flex-col gap-3 mb-5">
            {/* Email + Phone */}
            <div className="flex justify-between items-center">
              {/* Email */}
              <div className="flex flex-col justify-center items-start w-full gap-1">
                <p className="uppercase text-gray-500 text-xs">email address</p>
                <p className="text-sm">{user?.email}</p>
              </div>
              {/* Phone */}
              <div className="flex flex-col justify-center items-start w-full gap-1">
                <p className="uppercase text-gray-500 text-xs">phone number</p>
                <p className="text-sm">{user?.phoneNumber}</p>
              </div>
            </div>

            {/* Status + Created at */}
            <div className="flex justify-between items-center">
              {/* Status */}
              <div className="flex flex-col justify-center items-start w-full gap-1">
                <p className="uppercase text-gray-500 text-xs">
                  account status
                </p>
                <p
                  className={`text-sm  px-6 py-1 rounded border ${
                    user?.status === "Active"
                      ? "bg-green-100 text-green-700 border-green-300"
                      : user?.status === "Pending"
                      ? "bg-blue-100 text-blue-700 border-blue-300"
                      : user?.status === "Rejected"
                      ? "bg-gray-100 text-gray-700 border-gray-300"
                      : "bg-red-100 text-red-700 border-red-300"
                  }`}
                >
                  {user?.status}
                </p>
              </div>
              {/* Phone */}
              <div className="flex flex-col justify-center items-start w-full gap-1">
                <p className="uppercase text-gray-500 text-xs">
                  account created date
                </p>
                <p className="text-sm">{formatDate(user?.createdAt)}</p>
              </div>
            </div>
          </div>

          <hr />

          {user?.role === "Client" && (
            <>
              {/* Organization Info */}
              <p className="font-medium text-[15px] mt-5 mb-3">
                Organization Information
              </p>

              <div className="flex flex-col gap-3 mb-5">
                {/* Org name + years */}
                <div className="flex justify-between items-center">
                  {/* Org name */}
                  <div className="flex flex-col justify-center items-start w-full gap-1">
                    <p className="uppercase text-gray-500 text-xs">
                      Organization Name
                    </p>
                    <p className="text-sm">{user?.organizationName}</p>
                  </div>
                  {/* Years */}
                  <div className="flex flex-col justify-center items-start w-full gap-1">
                    <p className="uppercase text-gray-500 text-xs">
                      Years of operation
                    </p>
                    <p className="text-sm">{user?.yearsOfOperation}</p>
                  </div>
                </div>

                {/* location + url */}
                <div className="flex justify-between items-center">
                  {/* location */}
                  <div className="flex flex-col justify-center items-start w-full gap-1">
                    <p className="uppercase text-gray-500 text-xs">
                      office location
                    </p>
                    <p className="text-sm">{user?.officeLocation}</p>
                  </div>
                  {/* url */}
                  <div className="flex flex-col justify-center items-start w-full gap-1">
                    <p className="uppercase text-gray-500 text-xs">
                      website url
                    </p>
                    <Link
                      to={user?.websiteUrl}
                      className="text-sm text-green-700 underline"
                    >
                      {user?.websiteUrl}
                    </Link>
                  </div>
                </div>

                {/* products and services */}
                <div className="flex flex-col justify-center items-start w-full gap-1">
                  <p className="uppercase text-gray-500 text-xs">
                    Products and Services
                  </p>
                  <p className="text-sm">{user?.organizationName}</p>
                </div>

                {/* prior experience */}
                <div className="flex flex-col justify-center items-start w-full gap-1">
                  <p className="uppercase text-gray-500 text-xs">
                    prior experience
                  </p>
                  <p className="text-sm">{user?.organizationName}</p>
                </div>
              </div>

              <hr />

              {/* Business License Info */}
              <p className="font-medium text-[15px] mt-5 mb-3">
                Business License
              </p>

              <List
                grid={{
                  gutter: 20,
                  column: 5,
                }}
                dataSource={user?.businessLicenses}
                renderItem={(license) => (
                  <List.Item>
                    <Image width={200} src={license} className="" />
                  </List.Item>
                )}
              />

              <hr />

              {/* Approve / Reject */}
              {/* {user.role === "Client" && user.status === "Pending" && (
                <div className="flex justify-end items-center mt-5 mb-3 gap-5">
                  <button
                    className="bg-transparent hover:bg-green-700 text-green-700 font-medium text-xs hover:text-white py-1 px-5 border border-green-700 hover:border-transparent rounded"
                    // onClick={handleApprove}
                  >
                    <div className="flex gap-2 justify-center items-center">
                      <HowToRegIcon fontSize="small" />
                      Approve
                    </div>
                  </button>
                  <button
                    className="bg-transparent hover:bg-red-500 text-red-500 font-medium text-xs hover:text-white py-1 px-5 border border-red-500 hover:border-transparent rounded"
                    // onClick={handleReject}
                  >
                    <div className="flex gap-2 justify-center items-center">
                      <PersonOffIcon fontSize="small" />
                      Reject
                    </div>
                  </button>
                </div>
              )} */}
            </>
          )}

          {/* Block / Unblock */}
          {/* {(user.status === "Active" || user.status === "Blocked") && (
            <div className="flex justify-end items-center mt-5 mb-3 gap-5">
              <button
                className={`bg-transparent 
                    ${
                      user.status === "Blocked"
                        ? "text-gray-400 border-gray-400"
                        : "hover:bg-red-500 text-red-500 border-red-500 hover:text-white hover:border-transparent"
                    } font-medium text-xs py-1 px-5 border rounded`}
                // onClick={handleBlock}
                disabled={user.status === "Blocked"}
              >
                <div className="flex gap-2 justify-center items-center">
                  <BlockIcon fontSize="small" />
                  Block
                </div>
              </button>

              <button
                className={`bg-transparent 
                    ${
                      user.status === "Active"
                        ? "text-gray-400 border-gray-400"
                        : "hover:bg-green-700 text-green-700 border-green-700 hover:text-white hover:border-transparent"
                    } font-medium text-xs py-1 px-5 border rounded`}
                // onClick={handleApprove}
                disabled={user.status === "Active"}
              >
                <div className="flex gap-2 justify-center items-center">
                  <CheckCircleOutlineIcon fontSize="small" />
                  Unblock
                </div>
              </button>
            </div>
          )} */}
        </div>
      )}
    </>
  );
};

export default Profile;
