import React from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import bannerImage from "../assets/coffee1.png";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Banner */}
      <section className="">
        <div className="max-w-screen-2xl flex flex-row items-center justify-between mx-auto p-4">
          {/* Text */}
          <div className="w-full flex flex-col">
            <p className="text-[50px] font-bold leading-[70px]">
              Crafting the Perfect <br /> Filtered coffee
            </p>
            <p className="leading-[30px] mt-3 mb-7">
              Our commitment to quality in every stage of coffee filtering and
              <br />
              export brings the world's best flavors to your doorstep.
            </p>
            <button
              type="button"
              className="w-52 text-white bg-black hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-10 py-2 text-center "
              onClick={() => navigate("/")}
            >
              Get Started
            </button>
          </div>

          {/* Pic */}
          <div className="w-full h-[calc(100vh-200px)] flex items-center">
            <img src={bannerImage} alt="Coffee" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
