import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { message } from "antd";

const AuthContext = createContext();
const API_URL = process.env.REACT_APP_API_KEY;

const AuthProvider = ({ children }) => {
  //! States
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  //! Effects
  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setUser({
          userId: decoded.userId,
          email: decoded.email,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          role: decoded.role,
        });
        console.log(user);
      } catch (error) {
        console.error("Invalid token:", error);
        logOut();
      }
    }
    // eslint-disable-next-line
  }, [token]);

  //! Functions
  const loginAction = async (data, setErrorMessage) => {
    try {
      const response = await fetch(`${API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // console.log("Response:");
      // console.log(response);

      if (response.ok) {
        const res = await response.json();

        // console.log("Res:");
        // console.log(res);

        message.success("Signed in successfully!");

        if (res.access_token) {
          setUser(res.user);
          setToken(res.access_token);
          localStorage.setItem("site", res.access_token);

          if (res.user.role === "Client") navigate("/clientDashboard");
          else if (res.user.role === "Admin") navigate("/adminDashboard");
          else if (res.user.role === "Store") navigate("/storeDashboard");
          else if (res.user.role === "Operator") navigate("/operatorDashboard");
          else if (res.user.role === "Finance") navigate("/financeDashboard");
          return;
        }
        throw new Error(res.message);
      } else {
        const errorRes = await response.json();
        setErrorMessage(
          errorRes.error || "Something went wrong. Please try again."
        );
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("An unexpected error occurred. Please try again.");
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    message.success("Signed out successfully!");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
