import React from "react";
import { useApp } from "../../context/AppProvider";
import Sidebar from "../../components/client related/Sidebar";
import DashboardContent from "../../components/client related/DashboardContent";
import NotificationContent from "../../components/Notifications";
import ProfileContent from "../../components/ProfileContent";

const ClientDashboard = () => {
  const { selectedClientPage } = useApp();

  const renderContent = () => {
    switch (selectedClientPage) {
      case "Dashboard":
        return <DashboardContent />;
      case "Notifications":
        return <NotificationContent />;
      case "Profile":
        return <ProfileContent />;
      default:
        return <DashboardContent />;
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col w-full px-6 pt-8 bg-gray-50 max-h-screen overflow-y-auto">
        {renderContent()}
      </div>
    </div>
  );
};

export default ClientDashboard;
