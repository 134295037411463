import React, { useState } from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import AllUsersTable from "./subcomponents/AllUsersTable";
import PendingClientsTable from "./subcomponents/PendingClientsTable";
import AddUserForm from "./subcomponents/AddUserForm";
import UserDetails from "./subcomponents/UserDetails";

const UsersContent = () => {
  //! States
  const [activeTab, setActiveTab] = useState("allUsers"); // allUsers, pendingClients, userDetails
  const [selectedUserId, setSelectedUserId] = useState(null);

  //! Effects

  //! Functions
  const handleSetActiveTab = (tab, userId = "") => {
    setActiveTab(tab);
    setSelectedUserId(userId);
  };

  const getTitleAndBreadcrumb = () => {
    switch (activeTab) {
      case "addUser":
        return {
          title: "Add User",
          breadcrumb: [
            { title: <Link to="/">Home</Link> },
            {
              title: (
                <button
                  onClick={() => {
                    setActiveTab("allUsers");
                  }}
                >
                  Users
                </button>
              ),
            },
            { title: "Add User" },
          ],
        };
      case "pendingClients":
        return {
          title: "Pending Clients",
          breadcrumb: [
            { title: <Link to="/">Home</Link> },
            {
              title: (
                <button
                  onClick={() => {
                    setActiveTab("allUsers");
                  }}
                >
                  Users
                </button>
              ),
            },
            { title: "Pending Clients" },
          ],
        };
      case "userDetails":
        return {
          title: "User Details",
          breadcrumb: [
            { title: <Link to="/">Home</Link> },
            {
              title: (
                <button
                  onClick={() => {
                    setActiveTab("allUsers");
                  }}
                >
                  Users
                </button>
              ),
            },
            { title: "User Details" },
          ],
        };
      default:
        return {
          title: "Users",
          breadcrumb: [{ title: <Link to="/">Home</Link> }, { title: "Users" }],
        };
    }
  };

  const { title, breadcrumb } = getTitleAndBreadcrumb();

  const renderContent = () => {
    switch (activeTab) {
      case "addUser":
        return <AddUserForm setActiveTab={setActiveTab} />;
      case "pendingClients":
        return <PendingClientsTable handleSetActiveTab={handleSetActiveTab} />;
      case "userDetails":
        return <UserDetails userId={selectedUserId} />;
      default:
        return <AllUsersTable handleSetActiveTab={handleSetActiveTab} />;
    }
  };

  return (
    <>
      <div className="flex justify-between items-center gap-3 mb-5">
        <div className="flex flex-col flex-grow justify-center items-start">
          <p className="font-semibold text-lg">{title}</p>
          <p className="font-light text-[13px]">
            {activeTab === "allUsers"
              ? "A comprehensive list of all system users for administrative oversight"
              : activeTab === "addUser"
              ? "Fill out the form to add a new user to the system"
              : activeTab === "userDetails"
              ? "View all user information"
              : "View and manage clients awaiting approval"}
          </p>
        </div>

        <button
          className="bg-transparent hover:bg-green-700 text-green-700 font-medium text-xs hover:text-white py-1 px-5 border border-green-700 hover:border-transparent rounded"
          onClick={() => setActiveTab("addUser")}
        >
          <div className="flex gap-2 justify-center items-center">
            <PersonAddIcon fontSize="small" />
            Add User
          </div>
        </button>

        <button
          className="bg-transparent hover:bg-green-700 text-green-700 font-medium text-xs hover:text-white py-1 px-5 border border-green-700 hover:border-transparent rounded"
          onClick={() => setActiveTab("pendingClients")}
        >
          <div className="flex gap-2 justify-center items-center">
            <HourglassEmptyIcon fontSize="small" />
            Pending Clients
          </div>
        </button>
      </div>

      <Breadcrumb className="mb-8" items={breadcrumb} />

      <div className="relative bg-white p-5 mb-10 rounded shadow-lg flex-grow">
        {renderContent()}
      </div>
    </>
  );
};

export default UsersContent;
