import React from "react";
import Sidebar from "../../components/admin related/Sidebar";
import DashboardContent from "../../components/admin related/DashboardContent";
import UsersContent from "../../components/admin related/UsersContent";
import Notifications from "../../components/Notifications";
import ProfileContent from "../../components/ProfileContent";
import { useApp } from "../../context/AppProvider";

const AdminDashboard = () => {
  const { selectedAdminPage } = useApp();

  const renderContent = () => {
    switch (selectedAdminPage) {
      case "Dashboard":
        return <DashboardContent />;
      case "Users":
        return <UsersContent />;
      case "Notifications":
        return <Notifications />;
      case "Profile":
        return <ProfileContent />;
      default:
        return <DashboardContent />;
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-col w-full px-6 pt-8 bg-gray-50 max-h-screen overflow-y-auto">
        {renderContent()}
      </div>
    </div>
  );
};

export default AdminDashboard;
