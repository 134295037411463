import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import coffeeBag from "../assets/coffeeBag.png";
import logo from "../assets/EthioStarLogo.jpg";
import { Alert, Button, Form, Input } from "antd";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";

const Login = () => {
  //! States
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { user, loginAction } = useAuth();
  const navigate = useNavigate();

  //! Effects
  useEffect(() => {
    if (user) {
      if (user.role === "Admin") navigate("/adminDashboard");
      else if (user.role === "Client") navigate("/clientDashboard");
      else navigate("/");
    }
  }, [user, navigate]);

  //! Functions
  const onFinish = (values) => {
    loginAction(values, setErrorMessage);
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <section className="flex flex-row items-center justify-between mx-auto">
      {/* Side */}
      <div className="bg-smoothBg w-full h-screen flex flex-col">
        <p>x</p>
        <div className="flex flex-col items-center justify-center h-full gap-3">
          <p className="text-3xl font-semibold">Welcome!</p>
          <p className="italic font-light">
            Precision in every bean, from filter to package
          </p>
          <img src={coffeeBag} alt="Coffee bag" />
        </div>
      </div>

      {/* Form */}
      <div className="bg-white w-full h-screen">
        <div className="flex flex-col items-center justify-center h-full">
          <img src={logo} alt="EthioStar Logo" className="h-28 mb-12" />
          <p className="font-medium text-gray-400 mb-3">Welcome</p>
          <p className="font-bold text-[30px] mb-10">Sign in now</p>

          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              className="max-w-[600px] w-full mb-10 text-red-700 text-center"
            />
          )}

          <Form
            name="Login_Form"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="max-w-[600px] w-full"
          >
            <Form.Item
              name="email"
              className="mb-10"
              validateDebounce={1000}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "E-mail address is required!",
                },
              ]}
            >
              <Input
                prefix={<EmailIcon className="text-[#78C996] mx-3" />}
                placeholder="Email Address"
                className="h-11"
              />
            </Form.Item>

            <Form.Item
              name="password"
              validateDebounce={1000}
              rules={[
                {
                  required: true,
                  message: "Password field is required!",
                },
              ]}
            >
              {/* <Input.Password /> */}
              <Input.Password
                prefix={<LockIcon className="text-[#78C996] mx-3" />}
                placeholder="Password"
                className="h-11"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
              />
            </Form.Item>

            <div className="w-full flex justify-end mb-6">
              <Link to={"/"} className="text-green-700 hover:text-yellow-500">
                Forgot password?
              </Link>
            </div>

            <Form.Item label={null}>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full bg-green-700 hover:bg-yellow-500 h-11 font-semibold text-[16px] mb-12"
              >
                Login
              </Button>
            </Form.Item>

            <div className="w-full flex justify-center">
              <p>
                Don't have an account?
                <Link
                  to={"/signup"}
                  className="text-green-700 hover:text-yellow-500"
                >
                  {" "}
                  Create an account
                </Link>
              </p>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default Login;
