import React from "react";
import { useAuth } from "../../context/AuthProvider";
import { Link } from "react-router-dom";

const OperatorDashboard = () => {
  const auth = useAuth();

  return (
    <div className="container">
      <div>
        <h1>
          Hello {auth.user?.firstName}! Your role is {auth.user?.role}.
        </h1>
        <button onClick={() => auth.logOut()}>logout</button>
        <Link to={"/adminDashboard"}>Admin</Link>
        <Link to={"/login"}>Login</Link>
      </div>
    </div>
  );
};

export default OperatorDashboard;
