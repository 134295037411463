import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import { Alert, Button, Form, Input, Select } from "antd";
import { useAcc } from "../../../context/AccountProvider";
const { Option } = Select;

const AddUserForm = ({ setActiveTab }) => {
  const { registerSystemUser } = useAcc();

  //! States
  const [errorMessage, setErrorMessage] = useState(null);

  //! Effects

  //! Functions
  const onFinish = (values) => {
    registerSystemUser(values, setActiveTab, setErrorMessage);
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="bg-white w-full overflow-y-auto">
      <div className="flex flex-col items-start justify-center my-7 mx-7">
        <p className="font-semibold text-lg mb-2">New User Registration From</p>
        <p className="font-light text-sm text-gray-500 mb-7">
          This page allows administrators to register users associated with
          EthioStar. Using this form, you can add team members and assign them
          appropriate roles based on their responsibilities within the
          organization.
        </p>

        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            className="max-full w-full mb-10 text-red-700 text-center"
          />
        )}

        <Form
          name="User_Registeration_Form"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="w-full"
        >
          <div className="flex justify-between gap-8">
            {/* First Name */}
            <Form.Item
              name="firstName"
              className="mb-8 flex-grow"
              hasFeedback
              validateDebounce={1000}
              rules={[
                {
                  type: "string",
                  message: "The input is not a valid name!",
                },
                {
                  required: true,
                  message: "First name is required!",
                },
              ]}
            >
              <Input
                prefix={<PersonIcon className="text-[#78C996] mx-3" />}
                placeholder="First name"
                className="h-9"
                maxLength={15}
              />
            </Form.Item>

            {/* Last Name */}
            <Form.Item
              name="lastName"
              className="mb-8 flex-grow"
              hasFeedback
              validateDebounce={1000}
              rules={[
                {
                  type: "string",
                  message: "The input is not a valid name!",
                },
                {
                  required: true,
                  message: "Last name is required!",
                },
              ]}
            >
              <Input
                prefix={<PersonIcon className="text-[#78C996] mx-3" />}
                placeholder="Last name"
                className="h-9"
              />
            </Form.Item>
          </div>

          {/* Email */}
          <Form.Item
            name="email"
            className="mb-8"
            hasFeedback
            validateDebounce={1000}
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "E-mail address is required!",
              },
            ]}
          >
            <Input
              prefix={<EmailIcon className="text-[#78C996] mx-3" />}
              placeholder="Email Address"
              className="h-9"
            />
          </Form.Item>

          {/* Phone Number*/}
          <Form.Item
            name="phoneNumber"
            className="mb-8"
            hasFeedback
            validateDebounce={1000}
            rules={[
              {
                type: "string",
                message: "The input is not a valid phone number!",
              },
              {
                required: true,
                message: "Phone number is required!",
              },
            ]}
          >
            <Input
              prefix={<LocalPhoneIcon className="text-[#78C996] mx-3" />}
              placeholder="Phone number"
              className="h-9"
            />
          </Form.Item>

          {/* Role */}
          <Form.Item
            name="role"
            className="mb-8 flex-grow"
            rules={[
              {
                type: "string",
                message: "The input is not a valid name!",
              },
              {
                required: true,
                message: "First name is required!",
              },
            ]}
          >
            <Select
              prefix={<AccessibilityIcon className="text-[#78C996] mx-3" />}
              placeholder="Users role"
              allowClear
            >
              <Option value="Store">Store Keeper</Option>
              <Option value="Operator">Operator</Option>
              <Option value="Finance">Finance</Option>
            </Select>
          </Form.Item>

          {/* Button */}
          <div className="flex justify-end items-center w-full">
            <Form.Item label={null}>
              <Button
                type="primary"
                htmlType="submit"
                className="w-52 bg-green-700 hover:bg-yellow-500 h-9 font-semibold text-[16px] mb-12"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>

        <div className="absolute bottom-5 mr-5">
          <p className="text-sm font-medium">⚠️Warning</p>
          <p className="font-light text-sm text-gray-500 mb-7 italic">
            Please ensure the information entered is accurate and complete.
            Incorrect role assignments or invalid details can lead to system
            access issues or potential disruptions in operations. So please
            double-check before submitting the form, especially when handling
            sensitive user data like the users role.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddUserForm;
