import { notification, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useAcc } from "../../../context/AccountProvider";

const AllUsersTable = ({ handleSetActiveTab }) => {
  const { getAllUsers } = useAcc();

  const handleMoreClick = (user) => {
    handleSetActiveTab("userDetails", user);
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Link to={`/client-details/${record.userId}`}> */}
          <button
            onClick={() => {
              handleMoreClick(record.userId);
            }}
          >
            <MoreHorizIcon />
          </button>
        </Space>
      ),
    },
  ];

  //! States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allUsers, setAllUsers] = useState([]);

  //! Effects
  useEffect(() => {
    const fetchAllUsers = async () => {
      setLoading(true);
      setError(null);
      try {
        await getAllUsers(setAllUsers);
      } catch (err) {
        setError(err.message);
        notification.error({
          message: "Error Fetching Users",
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    };
    fetchAllUsers();
  }, [getAllUsers]);

  //! Functions
  console.log(error);

  return (
    <Table
      columns={columns}
      dataSource={allUsers.map((user) => ({ ...user, key: user.userId }))}
      loading={loading}
    />
  );
};

export default AllUsersTable;
